



















































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { getComponent, jsonParse } from "@/utils/helpers";
import accountModule from "@/store/modules/accountModule";
import assetsModule from "@/store/modules/assetsModule";
import scheduleModule from "@/store/modules/scheduleModule";
import { mixins } from "vue-class-component";
import Multiselect from "vue-multiselect";

@Component({
  components: {
    AppLayout: () => getComponent("common/AppLayout"),
    RigSchedule: () => getComponent("schedule/RigSchedule"),
    ComponentDetails: () => getComponent("schedule/ComponentDetails"),
    AddSchedule: () => getComponent("schedule/AddSchedule"),
    Multiselect,
  },
})
export default class ScheduleV2 extends mixins() {
  selectedColumnType = "DETAILS";
  userSelectionVal = "";
  yourValue = "";

  // NEEDED
  refreshResponseDataKey = 0;
  addShiftChecklistPopup = false;
  addShiftIsLoading = false;

  // NEEDED
  addShiftGroupSelected = "";

  // Loading views
  dataLoading = false;
  popupBackground = false;
  selectedDayOfWeek: any[] =[];
  repeatChangeValue = "";
  timeOptions: any[] = [];
  dayOptions: any[] = [];
  endTimeOptions: any[] = ["00:00"];
  recurEndTimeOptions: any[] = ["00:00"];
  userOptions: any[] = [];
  shiftNameErrorMessage = "";
  shiftColorErrorMessage = "";
  startTime='';
  endTime='';

  shiftName = '';
  shiftColor = '';
  swatches = [
    '#689df4', '#f3b344', '#ff7e75', '#5faea8', '#95ae5f', '#c15c50', '#b769ff', '#9a5fae',
    '#ff884f', '#ea5bee', '#f94144', '#f3722c', '#f8961e', '#f9c74f', '#43aa8b', '#c9ada7'
  ]

  get activeComponent() {
    return scheduleModule.activeComponent;
  }

  get activeColumns() {
    return scheduleModule.activeComponentResponseColumns;
  }

  setTimeOptions() {
    let value;
    for (let i = 0; i <= 23; i++) {
      for (let j = 0; j < 60; j+=15) {
        if (j == 0) {
          if (i < 10) value = `0${i}:0${j}`; else value = `${i}:0${j}`;
        } else {
          if (i < 10) value = `0${i}:${j}`; else value = `${i}:${j}`;
        }
        this.timeOptions.push(value);
      }
    }
  }

  setDayOptions() {
    for (let i = 1; i <= 30; i++) {
      this.dayOptions.push(i);
    }
  }

  setEndTimeOptions(value) {
    if (typeof value == "string" && value.constructor == String) {
      this.endTimeOptions = this.timeOptions.slice();
      for (let i = 0; i <=this.timeOptions.indexOf(value); i++) {
        this.endTimeOptions.shift();
      }
      this.endTime = this.endTimeOptions[0];
    }
  }

  setRecurEndTimeOptions(value) {
    if (typeof value == "string" && value.constructor == String) {
      this.recurEndTimeOptions = this.timeOptions.slice();
      for (let i = 0; i <=this.timeOptions.indexOf(value); i++) {
        this.recurEndTimeOptions.shift();
      }
    }
  }

  clickDayOfWeek(value) {
    
    if (this.selectedDayOfWeek.indexOf(value) === -1) {
      this.selectedDayOfWeek.push(value);
    } else {
      this.selectedDayOfWeek.splice(this.selectedDayOfWeek.indexOf(value),1)
    }
  }

  changeRepeatOption(value) {
    if (typeof value == "string" && value.constructor == String) {
      this.repeatChangeValue = value;
    }
  }

  selectSwatch(swatch) {
    this.shiftColor = swatch
  }

  // NEEDED
  addShiftHideClicked() {
    // this.addNewJobPopupInnerShowing = false
    // this.popupBackground = false
    this.$emit("hide-add-new-job-clicked");
  }

  async addShiftSave() {
    if (this.shiftName == "" || this.shiftColor == "") {
      if (this.shiftName == "") {
        this.shiftNameErrorMessage = "Please input new template's name";
      } else {
        this.shiftNameErrorMessage = ""
      }

      if (this.shiftColor == "") {
        this.shiftColorErrorMessage = "Please select new shift template's color";
      } else {
        this.shiftColorErrorMessage = ""
      }
      return;
    }
    
    await scheduleModule.postNewShiftTemplate({
      shiftName: this.shiftName,
      startTime: this.startTime, 
      endTime: this.endTime,
      shiftColor: this.shiftColor,
    });

    // @ts-ignore
    this.addShiftHideClicked();
  }

  addShiftResponseData(addShiftResponseData: any) {
    throw new Error("Method not implemented.");
  }

  get enabledWells() {
    return assetsModule.enabledWells;
  }

  async setUserOptions(){
    const items = await accountModule.getReassignmentList();
    this.userOptions.push(items.map(item=>{
      return item.name
    })) 
  }

  created() {
    this.setTimeOptions(), 
    this.setDayOptions();
  }
}
